<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <div class="flex-md-row-fluid ml-md-6 ml-lg-12">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="card p-6 gutter-b">
                                <!--begin::Header-->
                                <div class="card-header pt-0 pb-0">
                                    <div class="card-title font-size-h4 text-dark">
                                        <div class="card-label font-weight-bolder text-dark fs-15" v-if="programSessionDetail.datetime">
                                            {{ formatDate(programSessionDetail.datetime) }}
                                        </div>
                                        <span class="svg-icon svg-icon-primary"> </span>
                                    </div>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="col-md-12">
                                    <div class="col-md-6 offset-md-3">
                                        <div class="
                    card-body
                    pt-3
                    font-size-h6 font-weight-normal
                    text-dark
                  ">
                  <div class="smartphone mt-5 mb-5" v-if="videourl != ''">
                            <div class="content w-100 h-100 p-0">
                                <VideoPlayer :url=videourl :poster=poster />
                            </div>
                        </div>



                                            <!-- <div class="embed-responsive-16by9 col align-self-center">
                                                <video-player ref="videoPlayer" signUrl />
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div>
              <div
                class="
                  card-body
                  pt-3
                  font-size-h6 font-weight-normal
                  text-dark
                "
              >
                <div class="embed-responsive-16by9 col align-self-center">
                  <video-player ref="videoPlayer" signUrl />
                </div>
              </div>
            </div> -->
                                <!--end::Body-->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-12">
                            <!--begin::Forms Widget 2-->
                            <div class="card card-custom gutter-b">
                                <!--begin::Header-->
                                <div class="card-header border-0 pt-5 pb-4 mh-0">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label font-weight-bolder text-dark fs-15">Session Description</span>
                                    </h3>
                                </div>
                                <!--begin::Body-->
                                <div class="card-body py-0 pb-5">
                                    <!--begin::Top-->
                                    <div>
                                        <p class="text-dark font-size-lg font-weight-normal">
                                            {{ sessionTitle }}
                                        </p>
                                    </div>
                                    <!--end::Top-->
                                    <form class="position-relative mt-5">
                                        <label>Characters Remaining:
                                            {{
                                                    detailForm.maxChars - detailForm.sessionDescription.length
                                            }}</label>
                                        <div class="input-group">
                                            <input type="text" :maxlength="detailForm.maxChars"
                                                v-model="detailForm.sessionDescription" class="form-control"
                                                placeholder="Update Session Description" />
                                            <div class="input-group-append">
                                                <button class="btn btn-secondary" type="button"
                                                    @click="addSessionDetail">
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!--end::Body-->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="card card-custom gutter-b">
                                <!--begin::Header-->
                                <div class="card-header border-0 py-5">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label font-weight-bolder text-dark fs-15">Topics</span>
                                        <span class="text-dark mt-3 font-weight-bold font-size-sm">There are {{ rows }}
                                            topics</span>
                                    </h3>
                                    <div class="card-toolbar">
                                        <button type="button" class="
                    btn btn-success btn-sm
                    font-weight-bolder font-size-sm
                    text-white
                    mr-4
                  " @click="refreshReports">
                                            {{ issuesCount }} Reported Session Issues
                                        </button>
                                        <button type="button" @click="refreshChat" class="
                    btn btn-info btn-sm
                    font-weight-bolder font-size-sm
                    text-white
                    mr-4
                  ">
                                            Chat Transcript
                                        </button>
                                        <button type="button" @click="topicActive = true" class="
                    btn btn-ingomublue btn-sm
                    font-weight-bolder font-size-sm
                    text-white
                  ">
                                            <span class="svg-icon svg-icon-md svg-icon-white">
                                                <i class="la la-plus text-white"></i> </span>New Topic
                                        </button>
                                    </div>
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body py-0">
                                    <div :class="topicActive == true ? activeClass : 'hidden'">
                                        <div class="form-group">
                                            <input type="text" v-model="form.title"
                                                class="form-control form-control-solid"
                                                placeholder="Enter topic title" />
                                        </div>
                                        <div class="form-group">
                                            <wysiwyg v-model="form.text" />
                                            <div class="border-top my-5"></div>
                                            <div class="">
                                                <button type="button" class="btn btn-ingomublue text-white mr-2"
                                                    @click="addTopic">
                                                    Add
                                                </button>
                                                <button type="button" class="btn btn-link-dark font-weight-bold ml-4"
                                                    @click="hideTopicDetails">
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Table-->
                                    <div class="table-responsive" :class="topicActive == null ? activeClass : 'hidden'">
                                        <b-container fluid>
                                            <!-- User Interface controls -->
                                            <b-row class="mb-10">
                                                <b-col lg="6" class="my-1">
                                                    <b-form-group class="mb-0">
                                                        <b-input-group size="sm">
                                                            <b-form-input id="filter-input" v-model="filter"
                                                                type="search" placeholder="Type to Search">
                                                            </b-form-input>

                                                            <b-input-group-append>
                                                                <b-button :disabled="!filter" @click="filter = ''">Clear
                                                                </b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-table id="my-table" :items="sessionTopics" :fields="fields"
                                                :per-page="perPage" :current-page="currentPage" :filter="filter"
                                                :sort-compare="myCompare" show-empty
                                                class="table table-borderless table-vertical-center">
                                                <template #cell(image)="data">
                                                    <router-link :to="{
                                                        name: 'subscriberdetail',
                                                        params: { userId: data.item.user.user_id },
                                                    }">
                                                        <div class="symbol symbol-50 symbol-light mt-1">
                                                            <span class="symbol-label">
                                                                <img :src="imageSource(data.item.user.profileimage)"
                                                                    class="h-100 br-42 align-self-center" alt="" />
                                                            </span>
                                                        </div>
                                                    </router-link>
                                                </template>
                                                <template #cell(learner)="data">
                                                    <router-link :to="{
                                                        name: 'subscriberdetail',
                                                        params: { userId: data.item.user.user_id },
                                                    }" class="
                          text-dark text-hover-primary
                          mb-1
                          font-size-lg
                        ">
                                                        {{ data.item.user.firstname }}
                                                    </router-link>
                                                </template>
                                                <template #cell(title)="data">
                                                    <router-link :to="{
                                                        name: 'adminnotificationdetail',
                                                        params: { sessionsDiscussionId: data.item.id, coachlistid: data.item.coach_list_id },
                                                    }" class="
                          text-dark text-hover-primary
                          mb-1
                          font-size-lg
                        ">
                                                        {{ data.item.title.substring(0, 25) + ".." }}
                                                    </router-link>
                                                </template>
                                                <template #cell(comments)="data">
                                                    <span class="
                          text-dark text-hover-primary
                          mb-1
                          font-size-lg
                        ">
                                                        {{ data.item.commentCount }}
                                                    </span>
                                                </template>
                                                <template #cell(date)="data">
                                                    <a href="#" class="
                          text-dark text-hover-primary
                          mb-1
                          font-size-lg
                        ">{{ formatDate(data.item.lastUpdate) }}</a>
                                                </template>
                                                <template #cell(buttons)="data">
                                                    <router-link :to="{
                                                        name: 'adminnotificationdetail',
                                                        params: { sessionsDiscussionId: data.item.id, coachlistid: data.item.coach_list_id },
                                                    }" class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                        <i class="la la-arrow-right icon-xl text-ingomublue"></i>
                                                    </router-link>
                                                </template>
                                                <template #empty="scope">
                                                    <div class="text-center mt-10">
                                                        <h5>There are no topics yet!</h5>
                                                    </div>
                                                </template>
                                            </b-table>
                                        </b-container>
                                        <div class="mt-10">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                                align="left"></b-pagination>
                                        </div>
                                    </div>
                                    <!--end::Table-->
                                </div>
                                <!--end::Body-->
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal id="bv-modal-report" size="lg" scrollable centered hide-footer>
                    <template #modal-title>Reported Issues</template>
                    <div class="d-block text-center">
                        <div class="card-body pt-0">
                            <h5 v-if="videoIssues.length <= 0">There are no reported issues.</h5>
                            <!--begin::Item-->
                            <div v-else class="mb-6" v-for="(issue, index) in videoIssues" :key="index">
                                <!--begin::Content-->
                                <div class="d-flex align-items-center flex-grow-1">
                                    <!--begin::Checkbox-->
                                    <div class="symbol symbol-50px me-5">
                                        <img :src="issue.user.profileimage" class="" alt="" />
                                    </div>
                                    <!--end::Checkbox-->
                                    <!--begin::Section-->
                                    <div class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                  w-100
                ">
                                        <!--begin::Info-->
                                        <div class="
                    d-flex
                    flex-column
                    align-items-cente
                    text-left
                    py-2
                    w-50
                  ">
                                            <!--begin::Title-->
                                            <a href="javascript:void()" class="
                      text-dark
                      font-weight-bold
                      text-hover-primary
                      font-size-lg
                      mb-1
                      ml-5
                    ">{{ issue.type }}</a>
                                            <!--end::Title-->
                                            <!--begin::Data-->
                                            <span class="text-dark font-weight-bold ml-5">{{
                                                    issue.user.first
                                            }}</span>
                                            <!--end::Data-->
                                        </div>
                                        <!--end::Info-->
                                        <!--begin::Label-->
                                        <span class="
                    label label-lg label-light-primary label-inline
                    font-weight-bold
                    py-4
                  ">{{ formatDate(issue.datetime) }}</span>
                                        <!--end::Label-->
                                    </div>
                                    <!--end::Section-->
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Item-->
                        </div>
                    </div>
                </b-modal>
                <b-modal id="bv-modal-chat" size="lg" scrollable centered hide-footer>
                    <template #modal-title> Chat Transcript </template>
                    <div class="d-block text-center">
                        <div class="card-body">
                            <!--begin::Scroll-->
                            <div>
                                <!--begin::Messages-->
                                <div class="messages">
                                    <!--begin::Message In-->
                                    <h5 v-if="chatHistory.length <= 0">
                                        There were no chats this session.
                                    </h5>
                                    <div v-else v-for="(chat, index) in chatHistory" :key="index"
                                        class="d-flex flex-column mb-10 align-items-start"
                                        :class="chat.isCoach ? 'align-items-end' : 'align-items-start'">
                                        <div v-if="!chat.isCoach" class="d-flex align-items-center">
                                            <div class="symbol symbol-circle symbol-40 mr-3">
                                                <img alt="Pic" :src="chat.user.profileimage" />
                                            </div>
                                            <div>
                                                <a class="
                        text-dark text-hover-primary
                        font-weight-bold font-size-h6
                        me-1
                      ">{{ chat.user.firstname }}</a>
                                                <span class="text-dark font-size-sm">
                                                    <timeago :datetime="formatDate(chat.datetime)"></timeago>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else class="d-flex align-items-center">
                                            <div>
                                                <span class="text-dark font-size-sm">
                                                    <timeago :datetime="formatDate(chat.datetime)"></timeago>
                                                </span>
                                                <a class="
                        text-dark text-hover-primary
                        font-weight-bold font-size-h6
                        ms-1
                      ">You</a>
                                            </div>
                                            <div class="symbol symbol-circle symbol-40 ml-3">
                                                <img alt="Pic" :src="chat.user.profileimage" />
                                            </div>
                                        </div>
                                        <div v-if="!chat.isCoach" class="
                    mt-2
                    rounded
                    p-5
                    bg-light-success
                    text-dark
                    font-weight-bold font-size-lg
                    text-left
                    max-w-400px
                  ">
                                            {{ chat.message }}
                                        </div>
                                        <div v-else class="
                    mt-2
                    rounded
                    p-5
                    bg-light-primary
                    text-dark
                    font-weight-bold font-size-lg
                    text-right
                    max-w-400px
                  ">
                                            {{ chat.message }}
                                        </div>
                                    </div>
                                    <!--end::Message In-->
                                </div>
                                <!--end::Messages-->
                                <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
                                </div>
                                <div class="ps__rail-y" style="top: 0px; right: -2px; height: 375px">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 133px"></div>
                                </div>
                            </div>
                            <!--end::Scroll-->
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoPlayer from "@/view/pages/dashboard/FeedVideo.vue";
import formateDateMixin from "@/mixins/formatDate";
import showHideMixin from "@/mixins/showHide";
import Swal from "sweetalert2";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";

export default {
    name: "adminsessionDetail",
    components: {
        VideoPlayer,
    },
    mixins: [formateDateMixin, showHideMixin, defaultProfileImageMixin],
    data() {
        return {
            form: {
                title: "",
                text: "",
            },
            detailForm: {
                sessionDescription: "",
                maxChars: 200,
            },
            filter: null,
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            videourl: "",
            poster: "",
            fields: [
                { key: "image", label: "" },
                { key: "learner", label: "Name", sortable: true },
                { key: "title", label: "Topic", sortable: true },
                {
                    key: "comments",
                    label: "Comments",
                    sortable: true,
                    tdClass: "text-right",
                },
                { key: "date", label: "Last Update", sortable: true },
                { key: "buttons", label: "" },
            ],
            sessionTitle: "",
        };
    },
    methods: {
        myCompare(itemA, itemB, key) {
            if (key !== "date") {
                // If field is not `date` we let b-table handle the sorting
                return false;
            } else {
                // Convert the string formatted date to a number that can be compared
                // Get the values being compared from the items
                let a = itemA["lastUpdate"];
                let b = itemB["lastUpdate"];
                // Return the comparison result
                return a - b;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        addTopic() {
            if (
                this.form.title == "" ||
                this.form.text === "" ||
                this.form.text ===
                '<p style="margin-bottom: 15px; text-align: justify; color: rgb(0, 0, 0); font-family: &quot;Open Sans&quot;, Arial, sans-serif; font-size: 14px;"><br></p>'
            ) {
                Swal.fire({
                    title: "",
                    text: "You must enter some title and text!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                const newtitle = this.form.title;
                const newtext = this.form.text;
                this.$store.dispatch("addNewAdminTopic", {
                    coachlistid: this.$route.params.coachlistid,
                    sessionId: this.$route.params.sessionId,
                    title: newtitle,
                    text: newtext,
                });
                // this.hideTopicDetails();
                // this.form.title = "";
                // this.form.text = "";
            }
        },
        addSessionDetail() {
            if (this.detailForm.sessionDescription == "") {
                Swal.fire({
                    title: "",
                    text: "You must enter a session description!",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            } else {
                const newDescription = this.detailForm.sessionDescription;
                this.$store.dispatch("addAdminSessionDetail", {
                    title: newDescription,
                    coachlistid: this.$route.params.coachlistid,
                    sessionId: this.$route.params.sessionId,
                });
                this.detailForm.sessionDescription = "";
            }
        },
        refreshReports() {
            this.$store.dispatch("getAdminVideoIssues", {
                coachlistid: this.$route.params.coachlistid,
                sessionId: this.$route.params.sessionId,
            });
            this.$bvModal.show("bv-modal-report");
        },
        refreshChat() {
            this.$store.dispatch("getAdminChatHistory", {
                coachlistid: this.$route.params.coachlistid,
                sessionId: this.$route.params.sessionId,
            });
            this.$bvModal.show("bv-modal-chat");
        },
    },
    mounted() {
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: 2 });
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 2 });
        this.$store.dispatch("getAdminSessionDetail", {
            coachlistid: this.$route.params.coachlistid,
            sessionId: this.$route.params.sessionId,
        });
        this.$store.dispatch("getAdminTopicsBySession", {
            sessionId: this.$route.params.sessionId,
        });
        this.$store.dispatch("updateAdminIssuesCount", {
            coachlistid: this.$route.params.coachlistid,
            sessionId: this.$route.params.sessionId,
        });
        this.$store.commit("RESET_VIDEOISSUES");
        this.$store.commit("RESET_CHATHISTORY");
    },
    computed: {
        ...mapGetters([
            "programSessionDetail",
            "sessionTopics",
            "chatHistory",
            "videoIssues",
            "issuesCount",
        ]),
        rows() {
            return this.sessionTopics.length;
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    watch: {
        programSessionDetail(newValue, oldValue) {
            this.videourl = newValue.videourl
            this.poster = newValue.thumbnail
            if (newValue && newValue.title !== "" && newValue.title !== null) {
                this.sessionTitle = newValue.title;
            } else {
                this.sessionTitle = "No Sessions Yet";
            }
        },
    },
};
</script>

<style scoped>
.me-1 {
    margin-right: 0.25rem !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.video-js,
.video-js,
.vjs-tech {
    position: absolute !important;
    height: 100% !important;
    border-radius: none !important;
}


/* The device with borders */
.smartphone {
  position: relative;
  width: 360px;
  height: 600px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}

</style>