export default {
    data() {
        return {
            activeClass: 'is-visible',
            active: null,
            topicActive: null,
            replyActive: null
        };
    },
    methods: {
        showDetails(i) {
            this.active = i
        },
        showReplyDetails(i) {
            this.replyActive = i
        },
        hideDetails() {
            this.active = null
        },
        hideTopicDetails() {
            this.topicActive = null
        },
        hideReplyDetails() {
            this.replyActive = null
        }
    }
}